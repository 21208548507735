import { useCallback, useEffect, useState } from "react";
// import { useSearchParams } from "react-router-dom";

// import queryString from  'qs';

// const flattenFilter = (filter) => {
//     const flatFilter = Object.keys(filter).reduce((acc, key) => {
//         if(!filter[key].value || filter[key].value?.length === 0) return acc;
//         acc[key] = filter[key].value;
//         return acc;
//     }, {});
//     const uriParam = Object.keys(flatFilter).reduce((acc, key) => {
//         acc[filter[key].uriParam] = flatFilter[key];
//         return acc;
//     }, {})
//     return {flatFilter, uriParam};
// }

// const useFiltersBK = (initFilter) => {
//     const [filter, setFilter] = useState(initFilter);
//     const [searchParams, setSearchParams] = useSearchParams();
//     const qs = queryString.parse(searchParams.toString());
  
//     const setValue = useCallback((name, value) => {
//         setFilter(prevFilter => ({ ...prevFilter, [name]: { ...prevFilter[name], value } }));
//     }, []);

//     const getValue = useCallback((name) => {
//         return filter[name].value;
//     }, [filter]);

//     const toggleValue = useCallback((name) => {
//         setFilter(prevFilter => ({ ...prevFilter, [name]: { ...prevFilter[name], value: !prevFilter[name].value } }));
//     }, []);
    
//     useEffect(() => {
//         const {uriParam} = flattenFilter(filter);
//         setSearchParams(uriParam, {replace: true});
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [filter]);
//     const {flatFilter} = flattenFilter(filter);
//     return {setValue, getValue, toggleValue, filter, flatFilter};
// }

function simpleHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash).toString(36); // base36 pour raccourcir un peu
  }

const getHash = (name, initFilter) => {
    const data = JSON.stringify(Object.keys(initFilter).sort());
    const nameHash = `filters-${name}_${simpleHash(data)}`;
    return nameHash;
}

const getInternalFilter = (filter) => {
    return Object.keys(filter).reduce((acc, key) => {
        acc[key] = filter[key].defaultValue;
        return acc;
    }, {});
}

const getApiFilter = (filter, initFilter) => {
    return Object.keys(filter).reduce((acc, key) => {
        if(filter[key]?.length === 0 || initFilter[key]?.apiExport === false || (filter[key] === initFilter[key]?.defaultValue && initFilter[key]?.apiExportDefault !== true)) return acc;
        acc[key] = filter[key];
        return acc;
    }, {});
};

const getStoredFilters = (name) => {
    const storedFilters = sessionStorage.getItem(name);
    return storedFilters ? JSON.parse(storedFilters) : undefined;
  };

const useFilters = (initFilter, name) => {
    const nameHash = getHash(name, initFilter);
    const [filter, setFilter] = useState(getStoredFilters(nameHash) || getInternalFilter(initFilter));
    // const [searchParams, setSearchParams] = useSearchParams();
    // const qs = queryString.parse(searchParams.toString());
    useEffect(() => {
        sessionStorage.setItem(nameHash, JSON.stringify(filter));
      }, [filter, nameHash]);
  
    const setValue = useCallback((name, value) => {
        setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
    }, []);

    const toggleValue = useCallback((name, optionIndex = 0) => {
        setFilter(prevFilter => {
            const previousValue = prevFilter[name];
            if(Array.isArray(previousValue)){
                const optionValue = initFilter[name].options[optionIndex].value;
                if(previousValue.includes(optionValue)){
                    return { ...prevFilter, [name]: previousValue.filter(v => v !== optionValue)};
                }
                return { ...prevFilter, [name]: [...previousValue, optionValue]};
            }
            return { ...prevFilter, [name]: !previousValue};
        });
    }, [initFilter]);

    const getValue = useCallback((name, optionIndex = 0) => {
        return filter[name];
    }, [filter]);

    const apiFilter = getApiFilter(filter, initFilter);
    return {setValue, getValue, toggleValue, filter, apiFilter, nameHash};
}

export const getFilter = (name)=> {
    const filter = getStoredFilters(name) || {};
    return {
        filter,
        getValue : (name) => {
            return filter[name];
        }
    }
}

export default useFilters;