import React, { useContext } from 'react';
import './ContentLocked.scss';
import Button, { BUTTON_VARIANTS } from '../UI/Button/Button';
import { ModalContext } from '../ModalManager';
import CONTENT_RESTRICTION_RULE from '../../../const/contentRestrictionRule';
import createLink from "../../../libs/createLink";
import {useNavigate} from "react-router-dom";
import classNames from 'classnames';
import useLocalBreakpoint from '../../../hooks/useLocalBreakpoint';
import useCurrentUser from '../../../hooks/useCurrentUser';

const ACTION_LABELS = {
    SIGNUP: 'SIGNUP',
    SUBSCRIBE: 'SUBSCRIBE',
    CERTIFY: 'CERTIFY',
}

const BUTTON_TEXTS = {
    SIGNUP: "Créer un compte",
    SUBSCRIBE: "Découvrir l'offre premium",
    CERTIFY: 'Certifier mon compte',
    CANCEL: 'Peut-être plus tard'
}

const BACKGROUNDS_LABELS = {
    YELLOW: 'YELLOW',
    BLUE: 'BLUE',
    PINK: 'PINK',
    PURPLE: 'PURPLE',
    GREEN: 'GREEN',
}

const BACKGROUNDS = {
    [BACKGROUNDS_LABELS.YELLOW]: require("./images/backgrounds/bg-yellow.png"),
    [BACKGROUNDS_LABELS.BLUE]: require("./images/backgrounds/bg-blue.png"),
    [BACKGROUNDS_LABELS.PINK]: require("./images/backgrounds/bg-pink.png"),
    [BACKGROUNDS_LABELS.PURPLE]: require("./images/backgrounds/bg-purple.png"),
    [BACKGROUNDS_LABELS.GREEN]: require("./images/backgrounds/bg-green.png"),
}

const BACKGROUNDS_90 = {
    [BACKGROUNDS_LABELS.YELLOW]: require("./images/backgrounds/bg-yellow-90.png"),
    [BACKGROUNDS_LABELS.BLUE]: require("./images/backgrounds/bg-blue-90.png"),
    [BACKGROUNDS_LABELS.PINK]: require("./images/backgrounds/bg-pink-90.png"),
    [BACKGROUNDS_LABELS.PURPLE]: require("./images/backgrounds/bg-purple-90.png"),
    [BACKGROUNDS_LABELS.GREEN]: require("./images/backgrounds/bg-green-90.png"),
}

export const CONTENT_LOCKED_TYPES = {
    [CONTENT_RESTRICTION_RULE.ADD_TO_CIRCLE]: ({canBePriviliged})=>({
        title: <>{canBePriviliged ? 'Certifiez votre compte ou devenez premium pour' : 'Devenez premium pour'}<br/> créer votre cercle</>,
        description: <>Et leur donner accès à des contenus exclusifs<br/>(et bien plus encore !)</>,
        btnText : canBePriviliged ? BUTTON_TEXTS.CERTIFY : BUTTON_TEXTS.SUBSCRIBE,
        secondaryBtnText :canBePriviliged ? BUTTON_TEXTS.SUBSCRIBE : null,
        background: BACKGROUNDS_LABELS.GREEN,
        image : require("./images/icons/circle.png"),
        action : canBePriviliged ? ACTION_LABELS.CERTIFY : ACTION_LABELS.SUBSCRIBE,
        secondaryAction : canBePriviliged ? ACTION_LABELS.SUBSCRIBE : null
    }),
    [CONTENT_RESTRICTION_RULE.MEMBERS_FILTER]: ({canBePriviliged})=>({
        title: <>{canBePriviliged ? 'Certifiez votre compte ou devenez premium pour' : 'Devenez premium pour'} utiliser ce filtre</>,
        description: 'Profitez d’un accès illimité à tous les contenus avec Voissa Premium',
        btnText : canBePriviliged ? BUTTON_TEXTS.CERTIFY : BUTTON_TEXTS.SUBSCRIBE,
        secondaryBtnText :canBePriviliged ? BUTTON_TEXTS.SUBSCRIBE : null,
        image : require("./images/icons/members.jpg"),
        imageHeight : 150,
        action : canBePriviliged ? ACTION_LABELS.CERTIFY : ACTION_LABELS.SUBSCRIBE,
        secondaryAction : canBePriviliged ? ACTION_LABELS.SUBSCRIBE : null
    }),
    [CONTENT_RESTRICTION_RULE.MESSENGER]: ({username, canBePriviliged})=> ({
        title: <>{canBePriviliged ? 'Certifiez votre compte ou devenez premium pour' : 'Devenez premium pour'} chatter avec <span>{username || 'ce membre'}</span></>,
        description: <>Et profiter de Vmessenger en illimité<br/>(et bien plus encore) !<br/><br/>Plus 200 000 membres s’échangent plus de 100 000 messages et de milliers de photos et vidéos en privé</>,
        btnText : canBePriviliged ? BUTTON_TEXTS.CERTIFY : BUTTON_TEXTS.SUBSCRIBE,
        secondaryBtnText :canBePriviliged ? BUTTON_TEXTS.SUBSCRIBE : null,
        background: BACKGROUNDS_LABELS.PURPLE,
        image : require("./images/icons/messenger.png"),
        action : canBePriviliged ? ACTION_LABELS.CERTIFY : ACTION_LABELS.SUBSCRIBE,
        secondaryAction : canBePriviliged ? ACTION_LABELS.SUBSCRIBE : null
    }),
    [CONTENT_RESTRICTION_RULE.CHAT_WEBCAM]:  ({canBePriviliged})=>({
        title: <>{canBePriviliged ? 'Certifiez votre compte ou devenez premium pour' : 'Devenez premium pour'} rejoindre le Chat/Webcam</>,
        description: "Et plus encore !",
        btnText : canBePriviliged ? BUTTON_TEXTS.CERTIFY : BUTTON_TEXTS.SUBSCRIBE,
        secondaryBtnText :canBePriviliged ? BUTTON_TEXTS.SUBSCRIBE : null,
        action : canBePriviliged ? ACTION_LABELS.CERTIFY : ACTION_LABELS.SUBSCRIBE,
        secondaryAction : canBePriviliged ? ACTION_LABELS.SUBSCRIBE : null,
        background: BACKGROUNDS_LABELS.YELLOW,
        image : require("./images/icons/webcam.png"),
    }),
    [CONTENT_RESTRICTION_RULE.VIEW_LAST_VISITORS]: ({canBePriviliged})=>({
        title: canBePriviliged ? 'Certifiez votre compte ou devenez premium pour voir tous vos visiteurs.' : 'Vous souhaitez voir tous vos visiteurs ?',
        description: canBePriviliged ? "Et plus encore !" : "Profitez d’un accès illimité à tous les contenus avec Voissa Premium",
        btnText : canBePriviliged ? BUTTON_TEXTS.CERTIFY : BUTTON_TEXTS.SUBSCRIBE,
        secondaryBtnText :canBePriviliged ? BUTTON_TEXTS.SUBSCRIBE : null,
        action : canBePriviliged ? ACTION_LABELS.CERTIFY : ACTION_LABELS.SUBSCRIBE,
        secondaryAction : canBePriviliged ? ACTION_LABELS.SUBSCRIBE : null,
        background: BACKGROUNDS_LABELS.BLUE,
        image : require("./images/icons/last-visitors.png")
    }),
    [CONTENT_RESTRICTION_RULE.USER_BOOKMARKS_LIMIT]: {
        title: "Vous souhaitez voir tous vos favoris ?",
        description: "Profitez d’un accès illimité à tous les contenus avec Voissa Premium",
        btnText : BUTTON_TEXTS.SUBSCRIBE,
        action: ACTION_LABELS.SUBSCRIBE,
        background: BACKGROUNDS_LABELS.PURPLE,
        image : require("./images/icons/bookmarks.png"),
    },
    [CONTENT_RESTRICTION_RULE.VIDEO_DURATION_LIMIT]: ({videoDurationLimit})=> ({
        title: "Inscrivez-vous pour accéder à la vidéo complète",
        description: `Cette vidéo est limitée à ${videoDurationLimit} secondes pour les utilisateurs non inscrits`,
        btnText : BUTTON_TEXTS.SIGNUP,
        action: ACTION_LABELS.SIGNUP,
        background: BACKGROUNDS_LABELS.PINK,
        image : require("./images/icons/video.png"),
    }),
    [CONTENT_RESTRICTION_RULE.USER_NOTIFICATIONS_LIMIT]: {
        title: "Vous souhaitez voir toutes vos notifications ?",
        description: "Profitez d’un accès illimité à tous les contenus avec Voissa Premium",
        btnText : BUTTON_TEXTS.SUBSCRIBE,
        action: ACTION_LABELS.SUBSCRIBE,
        background: BACKGROUNDS_LABELS.PURPLE,
        image : require("./images/icons/notifications.png"),
    },
    [CONTENT_RESTRICTION_RULE.PROFIL_FEED_PUBLICATIONS_LIMIT]: ({isGuestLocked})=>({
        title: "Vous souhaitez voir toutes ses publications ?",
        description: isGuestLocked ? "Inscrivez-vous maintenant pour profiter de plus de contenus" : "Profitez d’un accès illimité à tous les contenus avec Voissa Premium",
        btnText : isGuestLocked ? BUTTON_TEXTS.SIGNUP : BUTTON_TEXTS.SUBSCRIBE,
        action: isGuestLocked ? ACTION_LABELS.SIGNUP : ACTION_LABELS.SUBSCRIBE,
        background: BACKGROUNDS_LABELS.PURPLE,
        image : require("./images/icons/feed.png"),
    }),
    [CONTENT_RESTRICTION_RULE.PROFILE_MEDIAS_LIMIT]: ({isGuestLocked})=>({
        title: "Vous souhaitez voir tous ses médias ?",
        description: isGuestLocked ? "Inscrivez-vous maintenant pour profiter de plus de contenus" : "Profitez d’un accès illimité à tous les contenus avec Voissa Premium",
        btnText : isGuestLocked ? BUTTON_TEXTS.SIGNUP : BUTTON_TEXTS.SUBSCRIBE,
        action: isGuestLocked ? ACTION_LABELS.SIGNUP : ACTION_LABELS.SUBSCRIBE,
        background: BACKGROUNDS_LABELS.PURPLE,
        image : require("./images/icons/medias.png"),
    }),
    [CONTENT_RESTRICTION_RULE.PROFIL_COLLECTION_MEDIAS_LIMIT]: ({isGuestLocked})=>({
        title: "Vous souhaitez voir tous ses médias ?",
        description: isGuestLocked ? "Inscrivez-vous maintenant pour profiter de plus de contenus" : "Profitez d’un accès illimité à tous les contenus avec Voissa Premium",
        btnText : isGuestLocked ? BUTTON_TEXTS.SIGNUP : BUTTON_TEXTS.SUBSCRIBE,
        action: isGuestLocked ? ACTION_LABELS.SIGNUP : ACTION_LABELS.SUBSCRIBE,
        background: BACKGROUNDS_LABELS.PURPLE,
        image : require("./images/icons/medias.png"),
    }),
    
}

const breakpoints = {
    md: 768
  };

const ContentLocked = ({restrictionRule, context = {}, inDrawer=false, handleCancel, ...props})=>{
    const { openModal } = useContext(ModalContext);
    const navigate = useNavigate();
    const currentUser =useCurrentUser();
    const [breakpoint, ref] = useLocalBreakpoint(breakpoints);
    let selectedContent = CONTENT_LOCKED_TYPES[restrictionRule];
    context = {...context, restrictionRule};
    if (!selectedContent) {
        return null;
    }

    if(typeof selectedContent === 'function') selectedContent = selectedContent(context);

    const handleAction = (action)=>{
        if(inDrawer) handleCancel();
        switch(action){
            case ACTION_LABELS.SIGNUP:
                openModal('Signup', { context });
                break;
            case ACTION_LABELS.SUBSCRIBE:
                navigate(createLink("subscription"));
                break;
            case ACTION_LABELS.CERTIFY:
                navigate(createLink("userMeSettingsCertification", currentUser));
                break;
            default:
                return;
        }
    }

    const isBannerFormat = !breakpoint.md;

    let defaultBackground = BACKGROUNDS[selectedContent.background];
    if(isBannerFormat) defaultBackground = BACKGROUNDS_90[selectedContent.background];

    const backgroundStyle = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundImage: `url(${selectedContent.backgroundCustom || defaultBackground})`,
        
    }

    if(isBannerFormat){
        backgroundStyle.backgroundSize = 'cover';
        backgroundStyle.backgroundPosition = 'center right';
        backgroundStyle.backgroundRepeat = 'no-repeat';
    }

    return <div className={classNames("content-locked", {'content-locked-card' : !inDrawer, 'content-locked-banner' : isBannerFormat})} {...props}  ref={ref}>
        <div className="content-locked-head" style={backgroundStyle}>
            <img src={selectedContent.image} alt="" height={selectedContent.imageHeight || 'auto'} className="content-locked-image" />
        </div>
        <div className="content-locked-body">
            <div className="content-locked-title">{selectedContent.title}</div>
            {selectedContent.description && <div className="content-locked-description">{selectedContent.description}</div>}
            <div className="content-locked-action">
                <Button variant={BUTTON_VARIANTS.REVERSE} big full onClick={()=>handleAction(selectedContent.action)}>{selectedContent.btnText}</Button>
                {inDrawer && <Button variant={selectedContent.secondaryAction ? BUTTON_VARIANTS.OUTLINE : BUTTON_VARIANTS.CTA_LIGHT} big full onClick={selectedContent.secondaryAction ? ()=>handleAction(selectedContent.secondaryAction) : handleCancel}>{selectedContent.secondaryBtnText || BUTTON_TEXTS.CANCEL}</Button>}
            </div>
        </div>
    </div>
}

export default ContentLocked;